import * as React from 'react'
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ErrorBoundary } from '../../../components/ErrorBoundary'
import { OrderIcon } from '@/components/OrderIcon'
import { SWLoadingButton } from '@/components/LoadingButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { FormDialogSubscriber } from '../form'
import { Unit } from '@/Units/types'
import { ISubscibersBody, ISubscriber } from '../types'
import { deleteSubscribers, editSubscriber, putSubscribers, updateBillingContact } from '../api'
import { IFormData } from '../form/types'
import { FormBulkSubscriber } from '../form/bulk'
import useMixPanel from '@/hooks/useMixPanel'
import { Structure } from '@/Structures/types'

type SortDirection = 'asc' | 'desc'

type SortOrder = -1 | 0 | 1

type SortableProperty = 'name' | 'start' | 'end' | 'volume' | 'cost'

type RowData = {
  id: string
  unit_name: string
  unit_id: string
  email: string
  first_name: string
  last_name: string
  isBillingContact: boolean
}

type Comparator = (a: RowData, b: RowData, orderBy?: SortableProperty) => SortOrder

interface IUnitSubscription {
  unit_id: string
  unit_name: string
  is_billing_contact: boolean
}

interface ISubscribers {
  id: string
  email: string
  first_name: string
  last_name: string
  unit_subscriptions: IUnitSubscription[]
}

// Util ///////////////////////////////////////////////////////////////////////

/**
 * Sort in descending order
 *
 * @param {object} a - First object to compare
 * @param {object} b - Second object to compare
 * @param {string} orderBy - Property name to order by
 *
 * @returns {-1 | 0 | 1} - Sort order
 */
const descendingComparator = (a: RowData, b: RowData, orderBy: SortableProperty) => {
  // $FlowFixMe
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  // $FlowFixMe
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

/**
 * Get sorting function
 *
 * @param {'asc' | 'desc'} order - Direction to sort
 * @param {*} orderBy - name to order by
 *
 * @returns {Function} - Function to sort with
 */
const getComparator = (order: SortDirection, orderBy: SortableProperty): Comparator => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy) as SortOrder
    : (a, b) => -descendingComparator(a, b, orderBy) as SortOrder
}

/**
 * Sort row data
 *
 * @param {RowData[]} array - Array of RowData
 * @param {Function} comparator - Comparing/sorting function
 *
 * @returns {RowData[]} - Sorted RowData
 */

const hiddenArrow = () => <span />
const stableSort = (array: RowData[], comparator: Comparator): RowData[] => {
  const stabilizedThis = array.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    // $FlowFixMe
    const order = comparator(a[0] as RowData, b[0] as RowData)

    if (order !== 0) return order

    return (a as number[])[1] - (b as number[])[1]
  })

  return stabilizedThis.map((el) => el[0] as RowData)
}

/**
 * Convert data to table rows
 *
 * @param  {object[]} suscribersArr - Array of Bills
 * @returns {object[]} - Array of rows
 */
const subscribersToRows = (subscribersArr: ISubscribers[]): RowData[] => {
  return subscribersArr.map((b) => ({
    id: b.id,
    unit_name: b.unit_subscriptions[0].unit_name || '',
    unit_id: b.unit_subscriptions[0].unit_id || '',
    email: b.email,
    first_name: b.first_name,
    last_name: b.last_name,
    isBillingContact: b.unit_subscriptions[0].is_billing_contact
  }))
}

// Components /////////////////////////////////////////////////////////////////

// Head ///////////////////////////////

const headCells = [
  { id: 'unit_name', numeric: false, disablePadding: false, label: 'Unit Name' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email Address' },
  { id: 'first_name', numeric: false, disablePadding: false, label: 'First Name' },
  { id: 'last_name', numeric: false, disablePadding: false, label: 'Last Name' }
]

type HeadProps = {
  classes: { [x: string]: string }
  order: SortDirection
  orderBy: SortableProperty
  onRequestSort: (event: React.MouseEvent, property: SortableProperty) => void
}

const EnhancedTableHead = ({ classes, order, orderBy, onRequestSort }: HeadProps) => {
  const createSortHandler = (property: SortableProperty) => (event: React.MouseEvent) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell align={'justify'}></TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="center" sortDirection={false}>
            <TableSortLabel IconComponent={hiddenArrow} onClick={createSortHandler(headCell.id as SortableProperty)}>
              {headCell.label}
              {orderBy === headCell.id && (
                <span
                  style={{
                    position: 'absolute',
                    right: '-25px'
                  }}
                >
                  <OrderIcon type={order} />
                </span>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align={'center'}>Invoice Contact</TableCell>
        <TableCell align={'justify'}></TableCell>
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = ({ onAdd, onBulkAdd }) => {
  return (
    <Box margin={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={0}>
        <Typography variant="h6" id="tableTitle" component="div"></Typography>
        <Box>
          <SWLoadingButton onClick={onAdd} sx={{ ml: 2 }}>
            Add Tenant
          </SWLoadingButton>
          <SWLoadingButton onClick={onBulkAdd} sx={{ ml: 2 }}>
            Import
          </SWLoadingButton>
        </Box>
      </Stack>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: `${theme.spacing(4)} 0 ${theme.spacing(3)}`
  },
  paper: {
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 650
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

type Props = {
  subscribers: ISubscribers[]
  units: Unit[]
  setNeedLoadData: Function
  structure: Structure
}

interface ISelectedSubscibersInfo {
  id: string
  UnitID: string
  UnitName: string
}

export const SuscribersTable = ({ subscribers, units, setNeedLoadData, structure }: Props): JSX.Element => {
  const [order, setOrder] = React.useState<SortDirection>('desc')
  const [orderBy, setOrderBy] = React.useState<SortableProperty>('start')
  const [page, setPage] = React.useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(5)
  const [isAddFormOpen, setIsAddFormOpen] = React.useState<boolean>(false)
  const [isEditFormOpen, setIsEditFormOpen] = React.useState<boolean>(false)
  const [isBulkFormOpen, setIsBulkFormOpen] = React.useState<boolean>(false)
  const [subscriberToEdit, setSubscriberToEdit] = React.useState<IFormData>()
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [selectedInfo, setSelectedInfo] = React.useState<ISelectedSubscibersInfo[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [propertyInfo] = React.useState({ PropertyID: structure.id, PropertyName: structure.name })
  const { trackEvent } = useMixPanel()

  const isSelected = (id: string) => selected.indexOf(id) !== -1

  const classes = useStyles()

  console.log(subscribers)
  const rows: RowData[] = subscribersToRows(subscribers)
  console.log(rows)

  const handleRequestSort = (event: React.MouseEvent, property: SortableProperty) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows: number = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null)
  const [menuSelectedId, setMenuSelectedId] = React.useState(null)

  const handleMenuClick = (event, id) => {
    setMenuAnchorEl(event.currentTarget)
    setMenuSelectedId(id)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
    setMenuSelectedId(null)
  }

  const handleAdd = async (body: ISubscibersBody, unitInfo: { unit_id: string; unit_name: string }) => {
    await putSubscribers(body)
    await trackEvent('Subscriber Invited', { ...propertyInfo, UnitID: unitInfo.unit_id, UnitName: unitInfo.unit_name })
    setNeedLoadData(true)
  }

  const handleAddBulk = async (body: ISubscibersBody, type: string) => {
    await putSubscribers(body)
    await trackEvent('Subscribers Imported', { ...propertyInfo, Count: body.subscribers.length, FileType: type })
    setNeedLoadData(true)
  }

  const handleEdit = async (body: ISubscriber, unitInfo: { unit_id: string; unit_name: string }) => {
    await editSubscriber(body)
    setNeedLoadData(true)
  }

  const handleDeleteSubscriber = async (id: string, unitInfo: { UnitID: string; UnitName: string }) => {
    setIsLoading(true)
    await deleteSubscribers({ subscriber_ids: [id] })
    await trackEvent('Subscriber Deleted', { ...propertyInfo, ...unitInfo })
    setNeedLoadData(true)
    setIsLoading(false)
    handleMenuClose()
  }

  const handleDeleteSubscribers = async () => {
    setIsLoading(true)
    await deleteSubscribers({ subscriber_ids: selected })
    await trackEvent('Subscribers Deleted', { ...propertyInfo, ...selectedInfo })
    setSelected([])
    setSelectedInfo([])
    setNeedLoadData(true)
    setIsLoading(false)
    handleMenuClose()
  }

  const handleUpdateBillingContact = async (unitId: string, subscriberId: string) => {
    setIsLoading(true)
    await updateBillingContact(unitId, subscriberId)
    setSelected([])
    setNeedLoadData(true)
    setIsLoading(false)
  }

  const handleOpenEdit = (id: string) => {
    const row = rows.find((i) => i.id === id)
    if (row) {
      const formatData: IFormData = {
        id: row.id,
        email: row.email,
        fist_name: row.first_name,
        last_name: row.last_name,
        unit: { id: row.unit_id, label: row.unit_name }
      }
      setSubscriberToEdit(formatData)
      setIsEditFormOpen(true)
    }
  }

  const handleClickItem = (row: RowData) => {
    const item = selected.find((i) => i === row.id)
    const itemInfo = selectedInfo.find((i) => i.id === row.id)
    if (item === undefined) setSelected([...selected, row.id])
    if (item) {
      const newSelected = selected.filter((item) => item !== row.id)
      setSelected(newSelected)
    }

    if (itemInfo === undefined)
      setSelectedInfo([...selectedInfo, { id: row.id, UnitID: row.unit_id, UnitName: row.unit_name }])
    if (itemInfo) {
      const newSelected = selectedInfo.filter((item) => item.id !== row.id)
      setSelectedInfo(newSelected)
    }
  }

  return (
    <ErrorBoundary>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar onAdd={() => setIsAddFormOpen(true)} onBulkAdd={() => setIsBulkFormOpen(true)} />
          <TableContainer>
            <Table className={classes.table} size="medium">
              <EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody sx={{ position: 'relative' }}>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const isItemSelected = isSelected(row.id)
                    const labelId = `enhanced-table-checkbox-${row.id}`
                    return (
                      <TableRow hover key={row.id} selected={isItemSelected} aria-checked={isItemSelected}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId
                            }}
                            onClick={() => handleClickItem(row)}
                          />
                        </TableCell>
                        <TableCell align="center">{row.unit_name}</TableCell>
                        <TableCell align="center">{row.email}</TableCell>
                        <TableCell align="center">{row.first_name}</TableCell>
                        <TableCell align="center">{row.last_name}</TableCell>
                        <TableCell align="center">
                          {row.isBillingContact && (
                            <Chip
                              label="Tenant Selected"
                              sx={{ backgroundColor: '#EDF4FA', color: '#4890CC', borderRadius: '8px' }}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-controls={`menu-${row.id}`}
                            aria-haspopup="true"
                            onClick={(event) => handleMenuClick(event, row.id)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id={`menu-${row.id}`}
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl) && menuSelectedId === row.id}
                            onClose={handleMenuClose}
                          >
                            {isLoading ? (
                              <MenuItem>Loading ...</MenuItem>
                            ) : selected.length > 1 ? (
                              <MenuItem
                                onClick={() => {
                                  handleDeleteSubscribers()
                                }}
                              >
                                Delete Tenants
                              </MenuItem>
                            ) : (
                              <>
                                {!row.isBillingContact && (
                                  <MenuItem
                                    onClick={() => {
                                      handleUpdateBillingContact(row.unit_id, row.id)
                                    }}
                                  >
                                    Select Tenant for Invoicing
                                  </MenuItem>
                                )}
                                <MenuItem
                                  onClick={() => {
                                    handleOpenEdit(row.id)
                                    handleMenuClose()
                                  }}
                                >
                                  Edit Tenant
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleDeleteSubscriber(row.id, { UnitName: row.unit_name, UnitID: row.unit_id })
                                  }}
                                >
                                  Delete Tenant
                                </MenuItem>
                              </>
                            )}
                          </Menu>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component={'div'}
            onPageChange={handleChangePage}
          />
        </Paper>
        <FormDialogSubscriber
          open={isAddFormOpen}
          onClose={() => setIsAddFormOpen(false)}
          type="ADD"
          onSubmit={(body, unitInfo) => handleAdd(body, unitInfo)}
          units={units}
        />

        <FormDialogSubscriber
          open={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          type="EDIT"
          onSubmit={(body, unitInfo) => handleEdit(body, unitInfo)}
          units={units}
          editData={subscriberToEdit}
        />
        <FormBulkSubscriber
          onClose={() => setIsBulkFormOpen(false)}
          open={isBulkFormOpen}
          units={units}
          onSubmit={(body, type) => handleAddBulk(body, type)}
        />
      </div>
    </ErrorBoundary>
  )
}
