import { Box, Card, CardContent, Dialog, Slide, Stack, Typography } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { SWLoadingButton } from '../LoadingButton'
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded'
import React from 'react'
import { TransitionProps } from '@mui/material/transitions'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface IProps {
  accountLink: Function
  stripeUserID: string
  setUpFinished: boolean
  connectAccount: Function
}

const StripeCard = ({ accountLink, stripeUserID, setUpFinished, connectAccount }: IProps) => {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <Box maxWidth={308}>
        <Card>
          <CardContent>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <img src="/img/stripe.png" width={48} height={48} alt="stripeLogo" />
              {stripeUserID !== '' && setUpFinished && <DeleteForeverIcon fontSize="large" sx={{ color: '#a8a8a8' }} />}
              {stripeUserID !== '' && !setUpFinished && (
                <ReplayRoundedIcon fontSize="large" sx={{ color: '#a8a8a8' }} />
              )}
            </Stack>
            <Stack direction={'row'} gap={'8px'} marginTop={'20px'}>
              <Typography fontSize={'28px'} fontWeight={700}>
                Stripe
              </Typography>
              {stripeUserID !== '' && <Chip variant={setUpFinished ? 'green' : 'yellow'} />}
            </Stack>
            <Typography fontSize={14} fontWeight={700} color={'#A8A8A8'}>
              {stripeUserID}
            </Typography>
            {stripeUserID === '' && (
              <Typography fontSize={14} fontWeight={400} color={'#1C1C1C'} marginTop={'4px'}>
                Seamlessly manage tenant billing with streamlined payments, invoicing, and secure transactions.
              </Typography>
            )}
            {stripeUserID !== '' && setUpFinished && (
              <Typography fontSize={14} fontWeight={400} color={'#1C1C1C'} marginTop={'4px'}>
                Your account is linked. Manage tenant billing, payments, and invoicing with ease.
              </Typography>
            )}
            {stripeUserID !== '' && !setUpFinished && (
              <Typography fontSize={14} fontWeight={400} color={'#1C1C1C'} marginTop={'4px'}>
                Almost there! It looks like you didn't finish connecting your account.
              </Typography>
            )}
            {stripeUserID === '' && (
              <Box marginTop={'20px'}>
                <SWLoadingButton variant="outlined" onClick={handleClickOpen}>
                  Connect
                </SWLoadingButton>
              </Box>
            )}

            {stripeUserID !== '' && !setUpFinished && (
              <Box marginTop={'20px'}>
                <SWLoadingButton
                  variant="contained"
                  onClick={async () => {
                    setLoading(true)
                    const url = await accountLink()
                    window.open(url, '_blank')
                    setLoading(false)
                  }}
                  loading={loading}
                >
                  Resume SetUp
                </SWLoadingButton>
              </Box>
            )}
            {stripeUserID !== '' && setUpFinished && (
              <Stack direction={'row'} gap={2} marginTop={'20px'} justifyContent={'center'}>
                <SWLoadingButton
                  variant="outlined"
                  onClick={async () => {
                    setLoading(true)
                    const url = await accountLink()
                    window.open(url, '_blank')
                    setLoading(false)
                  }}
                  loading={loading}
                >
                  Details
                </SWLoadingButton>
                <SWLoadingButton
                  variant="contained"
                  onClick={() => {
                    window.open('https://dashboard.stripe.com/', '_blank')
                  }}
                >
                  Dashboard
                </SWLoadingButton>
              </Stack>
            )}
          </CardContent>
        </Card>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{ textAlign: 'center' }}
      >
        <Stack justifyContent={'center'} margin={5} gap={2}>
          <img src="/img/worldClick.png" height={56} width={56} style={{ margin: 'auto' }} alt="timeLogo" />
          <Typography fontSize={24} fontWeight={700}>
            Complete Your Setup
          </Typography>
          <Typography fontSize={16} fontWeight={400}>
            To finish the setup, you'll be redirected to a new page.
            <br /> Please confirm if you'd like to proceed now.
          </Typography>
          <Stack direction={'row'} gap={2} justifyContent={'center'}>
            <SWLoadingButton variant="outlined" onClick={handleClose}>
              Cancel
            </SWLoadingButton>
            <SWLoadingButton
              variant="contained"
              onClick={async () => {
                setLoading(true)
                const url = await connectAccount()
                window.open(url, '_blank')
                handleClose()
                setLoading(false)
              }}
              loading={loading}
            >
              Continue
            </SWLoadingButton>
          </Stack>
        </Stack>
      </Dialog>
    </>
  )
}

const Chip = ({ variant }: { variant: 'green' | 'yellow' | 'red' }) => {
  return (
    <Box
      marginTop={'3px'}
      display={'flex'}
      padding={'4px 10px'}
      alignItems={'center'}
      gap={'4px'}
      sx={{ backgroundColor: variant === 'green' ? '#e8f3ee' : variant === 'yellow' ? '#fff9e6' : '#fcebec' }}
      borderRadius={'8px'}
    >
      <Typography
        fontSize={14}
        fontWeight={500}
        letterSpacing={0.1}
        color={variant === 'green' ? '#198754' : variant === 'yellow' ? '#6b5103' : '#dc3545'}
      >
        <DotIcon color={variant === 'green' ? '#198754' : variant === 'yellow' ? '#6b5103' : '#dc3545'} />
        {variant === 'green' ? 'Connected' : variant === 'yellow' ? 'Incomplete' : 'Error'}
      </Typography>
    </Box>
  )
}

const DotIcon = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      style={{ marginRight: '4px' }}
    >
      <mask id="mask0_2326_21538" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="9">
        <rect y="0.5" width="8" height="8" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2326_21538)">
        <path
          d="M4.00033 7.83342C3.53921 7.83342 3.10588 7.74591 2.70033 7.57091C2.29477 7.39591 1.94199 7.15842 1.64199 6.85842C1.34199 6.55841 1.10449 6.20564 0.929492 5.80008C0.754492 5.39453 0.666992 4.96119 0.666992 4.50008C0.666992 4.03897 0.754492 3.60564 0.929492 3.20008C1.10449 2.79453 1.34199 2.44175 1.64199 2.14175C1.94199 1.84175 2.29477 1.60425 2.70033 1.42925C3.10588 1.25425 3.53921 1.16675 4.00033 1.16675C4.46144 1.16675 4.89477 1.25425 5.30033 1.42925C5.70588 1.60425 6.05866 1.84175 6.35866 2.14175C6.65866 2.44175 6.89616 2.79453 7.07116 3.20008C7.24616 3.60564 7.33366 4.03897 7.33366 4.50008C7.33366 4.96119 7.24616 5.39453 7.07116 5.80008C6.89616 6.20564 6.65866 6.55841 6.35866 6.85842C6.05866 7.15842 5.70588 7.39591 5.30033 7.57091C4.89477 7.74591 4.46144 7.83342 4.00033 7.83342Z"
          fill={color}
        />
      </g>
    </svg>
  )
}
export default StripeCard
