import { getSession, urlWithAdminQuery } from '@/auth'
import { IAccontInfo, IBodyCreateAccountLink, IBodyUpdateUserInfo, ICreateAccountLinkResponse } from './types'
import { createDefaultHeaders } from '@/api'
import { toast } from 'react-toastify'
import { log } from '@/util'

export const getJSONOrThrow = (resp: Response) => {
  if (!resp.ok || resp.status < 200 || resp.status >= 400) {
    return resp.text().then((text) => {
      throw new Error(text)
    })
  }

  return resp.json()
}

/**
 * Get User Info from backend
 *
 * @returns {Promise} - Result of call to get user info
 */
export const getUserInfo = (): Promise<IAccontInfo> => {
  return getSession().then((session) => {
    let ep = `/user/${session.idToken.payload.sub || ''}`
    if (session.adminEmail !== '') ep = '/user/getByAdminEmail'
    return fetch(urlWithAdminQuery(ep, session.adminEmail), {
      method: 'GET',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    })
      .then(getJSONOrThrow)
      .catch((err) => {
        const newErr = new Error(`Error getting user: ${err.message}`)
        toast.error(newErr.message)
        log.error(newErr)
      })
  })
}

/**
 * Update User Info from backend
 *
 * @returns {Promise} - Result of call to Update user info
 */
export const updateUserInfo = (body: IBodyUpdateUserInfo, idUser: string): Promise<IAccontInfo> => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/user/${idUser || ''}`, session.adminEmail), {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    })
      .then(getJSONOrThrow)
      .catch((err) => {
        const newErr = new Error(`Error updating user: ${err.message}`)
        toast.error(newErr.message)
        log.error(newErr)
      })
  )
}

/**
 * Create a stripe connected account for the logged in user
 *
 * @returns {Promise} - Result of call to Update user info
 */
export const createStripeAccount = (): Promise<IAccontInfo> => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery('/stripe/account', session.adminEmail), {
      method: 'POST',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    })
      .then(getJSONOrThrow)
      .catch((err) => {
        const newErr = new Error(`Error creating Stripe account: ${err.message}`)
        toast.error(newErr.message)
        log.error(newErr)
      })
  )
}

/**
 * Create a stripe connected account for the logged in user
 *
 * @returns {Promise} - Result of call to Update user info
 */
export const createStripeAccountLink = (body: IBodyCreateAccountLink): Promise<ICreateAccountLinkResponse> => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery('/stripe/accountLink', session.adminEmail), {
      method: 'POST',
      body: JSON.stringify(body),
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    })
      .then(getJSONOrThrow)
      .catch((err) => {
        const newErr = new Error(`Error creating Stripe account link: ${err.message}`)
        toast.error(newErr.message)
        log.error(newErr)
      })
  )
}
