export interface IUserData {
  orgName: string
  connectedAccountId: string
  connectedAccountDetailsSubmitted: boolean
}

export interface IAccontInfo {
  user: IUser
}

interface IUser {
  id: string
  email: string
  note: string
  organization_name: string
  phone_number: string
  stripe_connected_account_id: string
  stripe_connected_account_details_submitted: boolean
}

export interface IBodyUpdateUserInfo {
  organization_name: string
}

export interface IBodyCreateAccountLink {
  link_type: STRIPE_ACCOUNT_LINK_TYPE
}

export enum STRIPE_ACCOUNT_LINK_TYPE {
  ACCOUNT_ONBOARDING = "ACCOUNT_ONBOARDING"
} 

export interface ICreateAccountLinkResponse {
  url: string
}